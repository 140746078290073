<template>
  <v-col class="text-left" cols="12">
    <v-card class="user-comment-card" v-if="comment" :id="'comm' + comment.id">
        <div class="user-comment-head">
          <div class="user-comment-head-left">
            откомментировал {{commentableTypeName}} <router-link
            :to="commentableLink"
          >{{ commentableName }}</router-link>
          </div>
          <div class="user-comment-date">{{ comment.public_date }}</div>
        </div>
        <div class="user-comment-body">{{ comment.body }}</div>
    </v-card>
  </v-col>
</template>
<script>
export default {
  props: ["comment", "user_name"],
  data() {
    return {
    };
  },
  methods: {},
  computed: {
    commentableTypeName(){
      if(this.comment){
        if(this.comment.commentable_type == 'Book'){
          return 'книгу';
        }
        else if(this.comment.commentable_type == 'Blog'){
          return 'блог';
        }
      }
      return '';
    },
    commentableName(){
      if(this.comment && this.comment.commentable){
        return this.comment.commentable.title;
      }
      return '';
    },
    commentableLink(){
      if(this.comment && this.comment.commentable){
        if(this.comment.commentable_type == 'Book'){
          return {name: 'BookShow', params: {slug: this.comment.commentable.slug}};
        }
        else if(this.comment.commentable_type == 'Blog'){
          return {name: 'BlogShow', params: {slug: this.comment.commentable.slug}}
        }
      }
      return '';
    }
  },
  mounted() {},
};
</script>
