<template>
  <div v-if="serie.books && serie.books.length > 0" class="serie-section">
    <v-col class="text-left pb-0" cols="12">
      <h4 class="serie-title">{{ serie.title }}</h4>
      <div v-if="serie.body_formating" v-html="serie.body_formating" class="serie-body"></div>
    </v-col>
    <book-card
      v-for="(book, index) in serie.books"
      :key="book.id"
      :book="book"
      :index="index"
    >{{book.id}}</book-card>
    <!-- <div v-if="!books || books.length == 0" class="book-tab-text">Данных нет</div> -->
  </div>
</template>
<script>
import BookCard from "../book/BookCard.vue";
export default {
  components: { BookCard },
  props: ["serie"],
  data() {
    return {};
  },
  methods: {}
};
</script>
