<template>
  <div>
    <v-toolbar tile flat elevation="1">
      <v-btn @click="$router.go(-1)" class="no-active" icon>
        <v-icon>$vuetify.icons.arrow_left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-toolbar-title v-if="user">{{ titlePage }}</v-toolbar-title>
    </v-toolbar>
    <v-container v-if="!loadPage">
      <v-card v-if="user" class="user-card">
        <div
          class="user-card-wrapper"
          :class="{'mobile-user-card-wrapper':isMobile ,
          'dark':user.cover_url}"
          :style="{ backgroundImage: 'url(' + user.cover_url + ')' }"
        >
          <div class="user-card-left">
            <div class="user-image">
              <img v-if="user.avatar_url" :src="user.avatar_url" />
            </div>
          </div>
          <div class="user-card-right">
            <div class="user-title">
              {{ user.public_name }}
              {{ subscribersCount ? " (" + subscribersCount + " подп.)" : "" }}
            </div>

            <div class="user-info">
              <p>{{ online }}</p>
              <!-- <p>Зарегистрирован: {{ user.register_at }}</p> -->
            </div>
            <div
              v-if="user.about_formating"
              v-html="user.about_formating"
              class="user-about"
            ></div>
          </div>
        </div>
        <v-card-actions  v-if="!isMobile" class="mt-auto pl-3 pr-3 pb-3 pt-0">
          <v-btn
            @click="subscribe"
            :color="inSubscribe ? 'green darken-3' : 'primary'"
            dark
            :loading="subscribeLoading"
            >{{ inSubscribe ? "Подписаны" : "Подписаться" }}</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-btn
        v-if="isMobile"
        @click="subscribe"
        :color="inSubscribe ? 'green darken-3' : 'primary'"
        dark
        block
        class="mt-4"
        :loading="subscribeLoading"
        >{{ inSubscribe ? "Подписаны" : "Подписаться" }}</v-btn
      >
      <!-- <v-card class="user-tabs mt-4">
        <v-toolbar color="primary" dark>
              <v-toolbar-title>комментарии</v-toolbar-title>
              <v-btn>asdasd</v-btn>
              <v-btn>asdasd</v-btn>
              <v-btn>asdasd</v-btn>
              <v-spacer></v-spacer>
              <v-btn icon @click="showCommentAdd = !showCommentAdd">
                <v-icon>$vuetify.icons.plus</v-icon>
              </v-btn>
            </v-toolbar>
            <book-card
                v-for="(book, index) in books"
                :key="book.id"
                :book="book"
                :index="index"
                >{{ book.id }}</book-card
              >
              <div v-if="!books || books.length == 0" class="tab-no-content">
                {{ noContent }}
              </div>
            <v-col cols="12">
              <v-pagination
                v-if="pages > 1"
                @input="goPage"
                v-model="page"
                :length="pages"
                :total-visible="7"
              ></v-pagination>
            </v-col>
      </v-card> -->
      <v-card
        :loading="contentLoading"
        id="user-tabs"
        class="user-tabs mt-4 pb-4"
      >
        <v-tabs v-model="tab" @change="goTab" background-color="primary" dark>
          <v-tabs-slider></v-tabs-slider>
          <v-tab href="#book">Книги</v-tab>
          <v-tab v-if="!hideSeries" href="#serie">Циклы</v-tab>
          <v-tab href="#blog">Блоги</v-tab>
          <v-tab href="#comment">Комментарии</v-tab>
          <v-tab href="#subscriptions">Отслеживает</v-tab>
          <v-tab href="#soc">Соц. сети</v-tab>

          <v-spacer></v-spacer>

          <v-menu
            v-if="tab == 'book' || tab == 'blog'"
            transition="slide-y-transition"
            left
            bottom
            offset-y
          >
            <template v-slot:activator="{ on }">
              <v-btn class="tab-button" text tile v-on="on">
                <span class="d-none d-sm-flex mr-1">Сортировка</span>
                <v-icon>$vuetify.icons.sort</v-icon>
              </v-btn>
            </template>
            <v-list class="text-right">
              <v-list-item-group color="primary">
                <v-list-item
                  v-for="(item, index) in sortings"
                  :key="index"
                  :class="item.val === sort ? 'active v-list-item--active' : ''"
                  @click="sortCategory(item.val)"
                >
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item value="book">
            <v-card flat>
              <book-card
                v-for="(book, index) in books"
                :key="book.id"
                :book="book"
                :index="index"
                >{{ book.id }}</book-card
              >
              <div
                v-if="!books || books.length == 0"
                class="tab-no-content text-center"
              >
                {{ noContent }}
              </div>
            </v-card>
          </v-tab-item>

          <v-tab-item value="serie">
            <v-card flat>
              <serie-card
                v-for="(item, index) in series"
                :key="item.id"
                :serie="item"
                :index="index"
                >{{ item.id }}</serie-card
              >
              <div
                v-if="!series || series.length == 0"
                class="tab-no-content text-center"
              >
                {{ noContent }}
              </div>
              <!-- <div v-else class="tab-no-content">Данных нет</div> -->
            </v-card>
          </v-tab-item>

          <v-tab-item value="blog">
            <v-card flat>
              <blog-card
                v-for="(blog, index) in blogs"
                :key="blog.id"
                :blog="blog"
                :index="index"
                :library="false"
                :admin="false"
                :cabinet="false"
              ></blog-card>
              <div
                v-if="!blogs || blogs.length == 0"
                class="tab-no-content text-center"
              >
                {{ noContent }}
              </div>
            </v-card>
          </v-tab-item>

          <v-tab-item value="comment">
            <v-card flat>
              <user-comment-card
                v-for="(comment, index) in comments"
                :key="comment.id"
                :comment="comment"
                :user_name="user.public_name"
                :index="index"
              ></user-comment-card>
              <div
                v-if="!comments || comments.length == 0"
                class="tab-no-content text-center"
              >
                {{ noContent }}
              </div>
            </v-card>
          </v-tab-item>
          <v-tab-item value="soc">
            <v-card flat class="pa-4" v-if="user && user.socProfiles">
              <user-soc-card
                v-for="(socProfile, index) in user.socProfiles"
                :key="index"
                :socProfile="socProfile"
                :index="index"
              ></user-soc-card>
              <div
                v-if="!user.socProfiles || user.socProfiles.length == 0"
                class="tab-no-content text-center"
              >
                {{ noContent }}
              </div>
            </v-card>
          </v-tab-item>

          <v-tab-item value="subscriptions">
            <v-card flat>
              <user-card
                v-for="(user, index) in subscriptions"
                :key="user.id"
                :user="user"
                :index="index"
              ></user-card>
              <div
                v-if="!subscriptions || subscriptions.length == 0"
                class="tab-no-content text-center"
              >
                {{ noContent }}
              </div>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
        <v-pagination
          v-if="pages > 1 && tab != 'soc'"
          @input="goPage"
          v-model="page"
          :length="pages"
          :total-visible="7"
          class="mt-2"
        ></v-pagination>
      </v-card>

      <!-- <v-dialog v-model="dialogdeleteUser" class="text-center" max-width="290">
        <v-card>
          <v-card-title class="headline justify-center">Вы уверены?</v-card-title>

          <v-card-text>Что хотите отписаться от данного пользователя.</v-card-text>

          <v-card-actions class="justify-space-around">
            <v-btn color="red darken-1" text @click="deleteUser">Да</v-btn>

            <v-btn color="green darken-1" text @click="dialogdeleteUser = false">Нет</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>-->
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import BookCard from "../book/BookCard.vue";
import SerieCard from "../book/SerieCard.vue";
import UserCommentCard from "../comment/UserCommentCard.vue";
import BlogCard from "../blog/BlogCard.vue";
import UserSocCard from "./UserSocCard.vue";
import UserCard from "./UserCard.vue";
export default {
  components: {
    BookCard,
    SerieCard,
    UserCommentCard,
    BlogCard,
    UserSocCard,
    UserCard,
  },
  data: () => ({
    sort: "order",

    title: "Пользователь",
    // dialogdeleteUser: false,
    // deleteData: null,
    tab: 'book',
    subscribeLoading: false,
    hideSeries: false,
  }),
  metaInfo() {
    if (this.user) {
      return {
        title: `${this.user.public_name}: все книги читать онлайн бесплатно`,
        meta: [
          {
            name: "description",
            content:
              "Автор " +
              this.user.public_name +
              " на сайте Bookstab. Читать книги полностью по сериям бесплатно онлайн. Информация об авторе, последние публикации, книги",
          },
          {
            property: "og:title",
            content:
              this.user.public_name + ": все книги читать онлайн бесплатно",
          },
          { property: "og:site_name", content: "Bookstab" },
          {
            property: "og:description",
            content:
              "Автор " +
              this.user.public_name +
              " на сайте Bookstab. Читать книги полностью по сериям бесплатно онлайн. Информация об авторе, последние публикации, книги",
          },
          { property: "og:type", content: "profile" },
          { property: "og:url", content: window.location.href },
          { property: "og:image", content: this.user.avatar_url },
        ],
      };
    }
    return {};
  },
  methods: {
    getUser() {
      var app = this;
      this.$store
        .dispatch("user/getUser", {
          user_name: app.userName,
          sort: app.sort,
        })
        .then((response) => {
          if (response.data.hideSeries) {
            app.hideSeries = true;
          }
          // if (response.data.success == 1) {
          //   app.pages = response.data.comments.last_page;
          // }
        })
        .catch(() => {});
    },
    getContent(nextPage) {
      var app = this;
      if (app.tab != "soc") {
        this.$store
          .dispatch("user/getContent", {
            user_id: app.user.id,
            sort: app.sort,
            type: app.tab,
            page: nextPage,
          })
          .then(() => {})
          .catch(() => {});
      }
    },
    subscribe() {
      if (!this.currentUser) {
        this.$root.showAuthMessage();
        return;
      }
      var app = this;
      app.subscribeLoading = true;
      this.$store
        .dispatch("user/subscribeUser", {
          user_id: app.user.id,
          sort: app.sort,
        })
        .then(() => {
          app.subscribeLoading = false;
        })
        .catch(() => {
          app.subscribeLoading = false;
        });
    },
    sortCategory(type) {
      if (type != this.sort) {
        this.sort = type;
        this.getContent(this.page);

        // this.$router.push({
        //   name: "UserShow",
        //   params: {},
        //   query: { sort: this.sort, tab: this.tab, page: this.page },
        // });
      }
    },
    goPage(nextPage) {
      // console.log(nextPage);
      this.$vuetify.goTo("#user-tabs");
      this.getContent(nextPage);
    },
    goTab() {
      // console.log(this.tab);
      // console.log(newTab);
      this.checkSorting();
      this.page = 1;
      this.getContent(this.page);
    },
    checkSorting() {
      // console.log(this.tab, this.sort);
      if (this.tab == "blog") {
        if (this.sort == "order") {
          this.sort = "last_comment";
        }
        if (this.sort == "popular") {
          this.sort = "views";
        }
      } else if (this.tab == "book") {
        if (this.sort == "last_comment") {
          this.sort = "order";
        }
        if (this.sort == "views") {
          this.sort = "popular";
        }
      }
      // else {
      //   this.sort = "new";
      // }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      user: "user/user",
      books: "user/books",
      series: "user/series",
      comments: "user/comments",
      blogs: "user/blogs",
      subscriptions: "user/subscriptions",
      subscribers: "user/subscribers",
      subscribersCount: "user/subscribersCount",
      pages: "user/pages",
      content_loading: "user/content_loading",
    }),
    page: {
      get() {
        return this.$store.getters["user/page"];
      },
      set(val) {
        this.$store.commit("user/SET_PAGE", val);
      },
    },
    titlePage() {
      if (+this.user.author) {
        return "Автор: " + this.user.public_name;
      } else {
        return "Пользователь: " + this.user.public_name;
      }
    },
    online() {
      if (this.user.online == "online") {
        return "В сети";
      } else {
        return "Был на сайте: " + this.user.online;
      }
    },
    inSubscribe() {
      if (
        this.currentUser &&
        this.subscribers &&
        this.subscribers.indexOf(this.currentUser.id) >= 0
      ) {
        return true;
      }
      return false;
    },
    contentLoading() {
      if (this.content_loading) {
        return "success";
      }
      return false;
    },

    sortings() {
      let arr = [];
      if (this.tab == "book") {
        arr.push({ text: "По умолчанию", val: "order" });
      }
      arr.push(
        { text: "По активности", val: "last_comment" },
        { text: "По новизне", val: "new" },
        {
          text: "По популярности",
          val: this.tab == "blog" ? "views" : "popular",
        },
        { text: "По комментариям", val: "comments" }
      );
      return arr;
    },
    noContent() {
      if (this.content_loading) {
        return "Загружаем...";
      }
      return "Данных нет";
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  mounted() {
    this.sort = this.$route.query.sort ? this.$route.query.sort : "order";
    this.tab = this.$route.query.tab ? this.$route.query.tab : 0;
    this.userName = this.$route.params.userName;
    this.page = this.$route.query.page ? Number(this.$route.query.page) : 1;
    this.getUser();
  },
};
</script>
