<template>
<v-col class="text-left" cols="12">
      <v-card v-if="user" class="user-mini-card">
        <div
          class="user-card-wrapper"
          :class="$vuetify.breakpoint.xsOnly ? 'mobile-user-card-wrapper' : ''"
        >
          <div class="user-card-left">
            <div class="user-image">
              <img v-if="user.avatar_url" :src="user.avatar_url" />
            </div>
          </div>
          <div class="user-card-right">
            <div class="user-title">
              <router-link
              :to="{ name: 'UserShow', params: { userName: user.name } }"
              >{{ user.public_name }}</router-link
            >
              
              <!-- {{ subscribersCount ? " (" + subscribersCount + " подп.)" : "" }} -->
            </div>
            <div class="user-min-info">
              <div class="count count_books">
                <v-icon>$vuetify.icons.bookshelf</v-icon> {{ user.books.length }}
                </div>
              <div class="count count_subscribers">
                <v-icon>$vuetify.icons.account_group</v-icon>  {{ user.count_subscribers }}
                </div>
              </div>
            <!-- <div class="user-info">
              <p>{{ online }}</p>
              <p>Зарегистрирован: {{ user.register_at }}</p>
            </div>
            <div
              v-if="user.about_formating"
              v-html="user.about_formating"
              class="user-about"
            ></div> -->
          </div>
        </div>
   
      </v-card>
     
   </v-col>
   
</template>
<script>
import { mapGetters } from "vuex";



export default {
  props: ["user"],
  components: {

  },
  data: () => ({

  }),
 
  methods: {
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",    
    }),
    online() {
      if (this.user && this.user.online == "online") {
        return "В сети";
      } else {
        return "Был на сайте: " + this.user.online;
      }
    },
  },
  mounted() {
 
  },
};
</script>
