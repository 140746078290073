<template>
  <v-col class="text-left" cols="12">
    <v-card class="blog-card" :class="blog.is_site_blog ? 'is_site_blog' : ''">
      <div
        class="blog-long d-flex flex-no-wrap justify-start"
        :class="$vuetify.breakpoint.xsOnly ? 'mobile' : ''"
      >
        <div class="blog-image">
          <router-link :to="{ name: 'BlogShow', params: { slug: blog.slug } }">
            <img :src="blog.user.avatar_url" />
          </router-link>
        </div>
        <div class="blog-long-body">
          <div
            class="blog-title"
            :class="$vuetify.breakpoint.xsOnly ? 'mobile' : ''"
          >
            <router-link
              :to="{ name: 'BlogShow', params: { slug: blog.slug } }"
              >{{ blog.title }}</router-link
            >
            <div class="blog-title-info">
              <v-icon small>$vuetify.icons.comment_text_outline</v-icon>
              {{ blog.count_comments }} | {{ blog.public_date }}
            </div>
          </div>
          <div class="blog-user-name">
            <router-link
              :to="{ name: 'UserShow', params: { userName: blog.user.name } }"
              >{{ blog.user.public_name }}</router-link
            >
          </div>
          <div
            v-if="$vuetify.breakpoint.smAndUp && blog.substr_excerpt"
            class="blog-text"
          >
            {{ blog.substr_excerpt }}
          </div>

          <div
            v-if="admin"
            class="blog-status"
            :class="+blog.status ? 'success--text' : 'error--text'"
          >
            {{ +blog.status ? "опубликован" : "черновик" }}
          </div>

          <v-card-actions class="mt-auto">
            <v-spacer></v-spacer>
            <v-btn v-if="library" @click.stop="deleteLibraryBlog" icon>
              <v-icon>$vuetify.icons.delete</v-icon>
            </v-btn>

            <v-btn
              v-if="!admin"
              :to="{ name: 'BlogShow', params: { slug: blog.slug } }"
              text
              tile
              >Подробнее</v-btn
            >

            <v-btn
              v-if="admin"
              :to="{ name: 'BlogShow', params: { slug: blog.slug } }"
              icon
            >
              <v-icon>$vuetify.icons.eye</v-icon>
            </v-btn>

            <v-btn
              v-if="admin"
              :to="{ name: 'CabinetBlogEdit', params: { id: blog.id } }"
              icon
            >
              <v-icon>$vuetify.icons.square_edit_outline</v-icon>
            </v-btn>
            <v-btn v-if="admin && cabinet" @click.stop="deleteBlog" icon>
              <v-icon>$vuetify.icons.delete</v-icon>
            </v-btn>
          </v-card-actions>
        </div>
      </div>
    </v-card>
  </v-col>
</template>
<script>
import { mapGetters } from "vuex";
// import BookInfo from "./BookInfo.vue";
export default {
  // components: { BookInfo },
  props: ["blog", "index", "library", "cabinet"],
  data() {
    return {};
  },
  methods: {
    deleteLibraryBlog() {
      this.$emit("remove-library-modal", [this.blog.id, "blog", this.index]);
    },
    deleteBlog() {
      this.$emit("remove-blog-modal", [this.blog.id, this.index]);
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
    admin() {
      if (
        this.currentUser &&
        this.blog &&
        this.currentUser.id == this.blog.user_id
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>
