<template>
  <div v-if="socProfile">
    <v-btn
      :color="socProfile.color"
      :href="socProfile.url"
      target="_blank"
      dark
      class="soc-link mt-4"
    >
      {{ socProfile.name }}</v-btn
    >
  </div>
</template>
<script>
export default {
  name: "UserSocCard",

  props: ["socProfile"],
  data() {
    return {};
  },
};
</script>
